import React, { useState, useEffect } from "react";
import { Col, Label, Row, Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { ethers } from "ethers";

const EditarJogo = ({ game, contrato, bracketProxy8 }) => {
  const [loading, setLoading] = useState(false);
  // eight teams
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [team3, setTeam3] = useState("");
  const [team4, setTeam4] = useState("");
  const [team5, setTeam5] = useState("");
  const [team6, setTeam6] = useState("");
  const [team7, setTeam7] = useState("");
  const [team8, setTeam8] = useState("");

  const [resultMatch1, setResultMatch1] = useState(0);
  const [resultMatch2, setResultMatch2] = useState(0);
  const [resultMatch3, setResultMatch3] = useState(0);
  const [resultMatch4, setResultMatch4] = useState(0);

  const [winner, setWinner] = useState("");
  const [secondPlace, setSecondPlace] = useState("");
  const [thirdPlace, setThirdPlace] = useState("");
  const [currentRound, setCurrentRound] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [activated, setActivated] = useState(0);

  const [round1, setRound1] = useState({});
  const [round2, setRound2] = useState({});
  const [round3, setRound3] = useState({});

  const convertToBytesData = (game, match) => {
    const zeroBigNumber = ethers.BigNumber.from(0);
    const matches = [
      ethers.utils.defaultAbiCoder.encode(
        ["uint8", "uint256", "uint256", "uint8"],
        [0, zeroBigNumber, zeroBigNumber, resultMatch1]
      ),
      ethers.utils.defaultAbiCoder.encode(
        ["uint8", "uint256", "uint256", "uint8"],
        [match === 8 ? 8 : 1, zeroBigNumber, zeroBigNumber, resultMatch2]
      ),
      ethers.utils.defaultAbiCoder.encode(
        ["uint8", "uint256", "uint256", "uint8"],
        [2, zeroBigNumber, zeroBigNumber, resultMatch3]
      ),
      ethers.utils.defaultAbiCoder.encode(
        ["uint8", "uint256", "uint256", "uint8"],
        [3, zeroBigNumber, zeroBigNumber, resultMatch4]
      ),
    ];

    const gameBigNumber = ethers.BigNumber.from(game);
    const gameIds = [
      gameBigNumber,
      zeroBigNumber,
      zeroBigNumber,
      zeroBigNumber,
    ];

    let matchData = [matches[match === 8 ? 1 : match], "0x", "0x", "0x"];

    const dataUpdate = ethers.utils.defaultAbiCoder.encode(
      ["uint256[4]", "bytes[4]"],
      [gameIds, matchData]
    );

    return dataUpdate;
  };

  const convertFromBytesData = (data) => {
    const decodedGameData = ethers.utils.defaultAbiCoder.decode(
      [
        "bytes",
        "bytes",
        "bytes",
        "string",
        "string",
        "string",
        "uint8",
        "uint256",
        "uint256",
        "uint8",
      ],
      data
    );
    setWinner(decodedGameData[3]);
    setSecondPlace(decodedGameData[4]);
    setThirdPlace(decodedGameData[5]);
    setCurrentRound(decodedGameData[6]);
    setStart(decodedGameData[7]);
    setEnd(decodedGameData[8]);
    setActivated(decodedGameData[9]);

    const decodedRound1 = ethers.utils.defaultAbiCoder.decode(
      ["string[8]", "uint8[4]", "uint256[8]", "uint256", "uint256"],
      decodedGameData[0]
    );

    setRound1({
      teams: decodedRound1[0],
      results: decodedRound1[1],
      scores: decodedRound1[2],
      start: decodedRound1[3],
      end: decodedRound1[4],
    });

    const decodedRound2 = ethers.utils.defaultAbiCoder.decode(
      ["string[8]", "uint8[4]", "uint256[8]", "uint256", "uint256"],
      decodedGameData[1]
    );

    setRound2({
      teams: decodedRound2[0],
      results: decodedRound2[1],
      scores: decodedRound2[2],
      start: decodedRound2[3],
      end: decodedRound2[4],
    });

    const decodedRound3 = ethers.utils.defaultAbiCoder.decode(
      ["string[8]", "uint8[4]", "uint256[8]", "uint256", "uint256"],
      decodedGameData[2]
    );

    setRound3({
      teams: decodedRound3[0],
      results: decodedRound3[1],
      scores: decodedRound3[2],
      start: decodedRound3[3],
      end: decodedRound3[4],
    });

    if (decodedGameData[6] == 0) {
      setTeam1(decodedRound1[0][0]);
      setTeam2(decodedRound1[0][1]);
      setTeam3(decodedRound1[0][2]);
      setTeam4(decodedRound1[0][3]);
      setTeam5(decodedRound1[0][4]);
      setTeam6(decodedRound1[0][5]);
      setTeam7(decodedRound1[0][6]);
      setTeam8(decodedRound1[0][7]);
      setResultMatch1(decodedRound1[1][0]);
      setResultMatch2(decodedRound1[1][1]);
      setResultMatch3(decodedRound1[1][2]);
      setResultMatch4(decodedRound1[1][3]);
    } else if (decodedGameData[6] == 1) {
      setTeam1(decodedRound2[0][0]);
      setTeam2(decodedRound2[0][1]);
      setTeam3(decodedRound2[0][2]);
      setTeam4(decodedRound2[0][3]);
      setTeam5("");
      setTeam6("");
      setTeam7("");
      setTeam8("");
      setResultMatch1(decodedRound2[1][0]);
      setResultMatch2(decodedRound2[1][1]);
      setResultMatch3(0);
      setResultMatch4(0);
    } else {
      setTeam1(decodedRound3[0][0]);
      setTeam2(decodedRound3[0][1]);
      setTeam3(decodedRound3[0][2]);
      setTeam4(decodedRound3[0][3]);
      setTeam5("");
      setTeam6("");
      setTeam7("");
      setTeam8("");
      setResultMatch1(decodedRound3[1][0]);
      setResultMatch2(0);
      setResultMatch3(0);
      setResultMatch4(0);
    }
  };

  const getGameFullData = async (game) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      try {
        const contract = new ethers.Contract(contrato, bracketProxy8, signer);
        const gameData = await contract.getGameFullData(game);
        convertFromBytesData(gameData);
      } catch (error) {
        console.error("Error on getGameFullData: ", error);
      }
    } catch (error) {
      console.error("Error on getGameFullData: ", error);
    } finally {
      setLoading(false);
    }
  };

  const updateGame = async (game, match) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const timestamp = Math.floor(Date.now() / 1000);
      const dataUpdate = convertToBytesData(game, match);
      console.log("dataUpdate", dataUpdate);
      console.log("timestamp", timestamp);
      let receipt;
      try {
        const contract = new ethers.Contract(contrato, bracketProxy8, signer);
        let estimateGas;

        try {
          estimateGas = await contract.estimateGas.performGames(
            "0x",
            dataUpdate,
            timestamp
          );
        } catch (error) {
          console.error(error);
          toast.error("Error on estimating GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.performGames(
          "0x",
          dataUpdate,
          timestamp,
          {
            gasLimit: estimateGas,
          }
        );
        receipt = await transactionResponse.wait();
      } catch (error) {
        console.error(error);
        toast.error("Error on updateGame");
        setLoading(false);
        return;
      }
      await getGameFullData(game);
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    } finally {
      setTeam1("");
      setTeam2("");
      setTeam3("");
      setTeam4("");
      setTeam5("");
      setTeam6("");
      setTeam7("");
      setTeam8("");
      setResultMatch1(0);
      setResultMatch2(0);
      setResultMatch3(0);
      setResultMatch4(0);

      await getGameFullData(game);
      setLoading(false);
    }
  };

  const activateGame = async (game) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      let receipt;
      try {
        const contract = new ethers.Contract(contrato, bracketProxy8, signer);
        let estimateGas;

        const gameBigNumber = ethers.BigNumber.from(game);

        try {
          estimateGas = await contract.estimateGas.activateGame(gameBigNumber);
        } catch (error) {
          console.error(error);
          toast.error("Error on estimating GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.activateGame(gameBigNumber, {
          gasLimit: estimateGas,
        });
        receipt = await transactionResponse.wait();
      } catch (error) {
        console.error(error);
        toast.error("Error on activateGame");
        setLoading(false);
        return;
      }
      await getGameFullData(game);
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getGameData = async (game) => {
      await getGameFullData(game);
    };

    if (game) getGameData(game);
  }, [game]);

  return (
    <React.Fragment>
      {game && (
        <>
          <h2>Edit Game {game}</h2>
          <h4 className="text-muted">Round {currentRound + 1}</h4>
        </>
      )}
      {loading ? (
        <Row className="justify-content-center">
          <Spinner />
        </Row>
      ) : activated === 0 ? (
        <>
          <h5>Game not activated</h5>
          <Row className="mt-5">
            <Col md={12}>
              <Button
                color="primary"
                className="btn btn-success"
                onClick={() => activateGame(game)}
                disabled={loading}
              >
                {loading && <Spinner size="sm" />} Activate Game
              </Button>
            </Col>
          </Row>
        </>
      ) : currentRound === 0 ? (
        <>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 1</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team1"
                  value={1}
                  checked={resultMatch1 === 1}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team1">
                  {team1}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team2"
                  value={2}
                  checked={resultMatch1 === 2}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team2">
                  {team2}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 0)}
                  disabled={round1?.results[0] > 0 || resultMatch1 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round1?.results[0] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 3</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match3"
                  id="team5"
                  value={1}
                  checked={resultMatch3 === 1}
                  onChange={(e) => setResultMatch3(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team5">
                  {team5}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match3"
                  id="team6"
                  value={2}
                  checked={resultMatch3 === 2}
                  onChange={(e) => setResultMatch3(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team6">
                  {team6}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 2)}
                  disabled={round1?.results[2] > 0 || resultMatch3 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round1?.results[2] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 2</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team3"
                  value={1}
                  checked={resultMatch2 === 1}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team3">
                  {team3}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team4"
                  value={2}
                  checked={resultMatch2 === 2}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team4">
                  {team4}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 1)}
                  disabled={round1?.results[1] > 0 || resultMatch2 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round1?.results[1] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 4</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match4"
                  id="team7"
                  value={1}
                  checked={resultMatch4 === 1}
                  onChange={(e) => setResultMatch4(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team7">
                  {team7}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match4"
                  id="team8"
                  value={2}
                  checked={resultMatch4 === 2}
                  onChange={(e) => setResultMatch4(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team8">
                  {team8}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 3)}
                  disabled={round1?.results[3] > 0 || resultMatch4 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round1?.results[3] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
        </>
      ) : currentRound === 1 ? (
        <>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 1</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team1"
                  value={1}
                  checked={resultMatch1 === 1}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team1">
                  {team1}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team2"
                  value={2}
                  checked={resultMatch1 === 2}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team2">
                  {team2}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 0)}
                  disabled={round2?.results[0] > 0 || resultMatch1 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round2?.results[0] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 2</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team3"
                  value={1}
                  checked={resultMatch2 === 1}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team3">
                  {team3}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team4"
                  value={2}
                  checked={resultMatch2 === 2}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team4">
                  {team4}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 1)}
                  disabled={round2?.results[1] > 0 || resultMatch2 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round2?.results[1] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
        </>
      ) : currentRound === 2 ? (
        <>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 1</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team1"
                  value={1}
                  checked={resultMatch1 === 1}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team1">
                  {team1}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team2"
                  value={2}
                  checked={resultMatch1 === 2}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team2">
                  {team2}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 0)}
                  disabled={round3?.results[0] > 0 || resultMatch1 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {round3?.results[0] > 0 ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match for Third Place</Label>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team3"
                  value={1}
                  checked={resultMatch2 === 1}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team3">
                  {team3}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team4"
                  value={2}
                  checked={resultMatch2 === 2}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team4">
                  {team4}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className="btn btn-info"
                  onClick={() => updateGame(game, 8)}
                  disabled={thirdPlace !== "" || resultMatch2 === 0}
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {thirdPlace !== "" ? "Decided" : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <h5>Winner: {winner}</h5>
          <h5>Second Place: {secondPlace}</h5>
          <h5>Third Place: {thirdPlace}</h5>
        </>
      )}
    </React.Fragment>
  );
};

export default EditarJogo;
