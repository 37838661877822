// export const proxyPhaseOne = "0x3Ae7eb97516eB5fEB17c3910A0518BC91Be713e0";
// export const nftPhaseOne = "0x9a6d94940C8Fb1C1b580723533300872e3DDbf75";
// export const proxyAddress = "0x156B4b3587F891e10214C0E63D297554FDFB3Fd1";
// export const nftAddress = "0xeB57C9DBd2DF686b0C906D669cDA061e3a0e0Fab";
// export const tokenAddress = "0x0120E0CE28d608db650149f8d33Dd9E94D34A042";

//producao
export const proxyPhaseOne = "0x793B0BC73d0d1d863992b6814F8235b1921aBd65";
export const proxyAddress = "0x6651DA845543883Caa5C8382Fa3e62FDb27Dc248";
export const nftAddress = "0x69b6293e82b51EE91F0B47E11466045c3B9170C3";
export const nftPhaseOne = "0xb21D15bFB19a376eEEB1957C3e21117a8Ec8595a";
export const tokenAddress = "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913";
