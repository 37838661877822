import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import {
  useActiveWalletChain,
  useSwitchActiveWalletChain,
} from "thirdweb/react";
import { defineChain } from "thirdweb/chains";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";
import BotaoConectar from "./BotaoConectar";

import logoLight from "../../assets/images/logo-light.png";
import { useActiveWallet } from "thirdweb/react";

import AOS from "aos";
import "aos/dist/aos.css";

const Login = (props) => {
  //produção
  const networks = [
    {
      name: "Base Chain",
      symbol: "ETH",
      network_id: 8453
    },
  ];

  // const networks = [
  //   {
  //     name: "Sepolia Base Chain",
  //     symbol: "ETH",
  //     network_id: 84532
  //   },
  // ];
  const chain = useActiveWalletChain();
  const switchChain = useSwitchActiveWalletChain();
  const changeChain = async (network) => {
    try {
      await switchChain(
        defineChain({
          id: network.network_id,
          nativeCurrency: {
            name: network.name,
            symbol: network.symbol,
            decimals: 18,
          },
        })
      );
    } catch (e) {
      console.error(e);
      if (e.code && e.code !== -32002) toast.error("Error on network switch");
      return false;
    }
    return true;
  };
  const [loading, setLoading] = useState(false);
  const carteira = useActiveWallet();

  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      duration: 3000,
      anchorPlacement: "top-bottom",
    });
  }, []);

  document.title = "Olympics Contracts | Perfect Pool Manager";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-between mt-5">
              <Col lg={12}>
                <Card
                  className="mt-5 card-bg-fill"
                  data-aos="fade-top"
                  style={{ borderRadius: "1rem" }}
                >
                  <CardBody className="p-4">
                    <Row>
                      <Col lg={12}>
                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                          <div>
                            <Link to="/" className="d-inline-block auth-logo">
                              <img src={logoLight} alt="" height="30" />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <div className="dflex justify-content-center mt-4 mb-4">
                        <BotaoConectar
                          changeChain={changeChain}
                          chain={networks[0]}
                          activeChain={chain}
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Login;
