import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { nftPhaseOne, tokenAddress } from "../../assets/contracts";
import { olympicsTicket } from "../../assets/abi/OlympicsTicket";
import { erc20 } from "../../assets/abi/ERC20";

const CriarNftPhaseOne = ({
  game,
  setMintNft,
  setNftId,
  nftId,
  group1,
  group2,
  group3,
}) => {
  const [loading, setLoading] = useState(false);

  const [resultMatch1, setResultMatch1] = useState(0);
  const [resultMatch2, setResultMatch2] = useState(0);
  const [resultMatch3, setResultMatch3] = useState(0);
  const [resultMatch4, setResultMatch4] = useState(0);
  const [resultMatch5, setResultMatch5] = useState(0);
  const [resultMatch6, setResultMatch6] = useState(0);
  const [resultMatch7, setResultMatch7] = useState(0);
  const [resultMatch8, setResultMatch8] = useState(0);
  const [resultMatch9, setResultMatch9] = useState(0);
  const [resultMatch10, setResultMatch10] = useState(0);
  const [resultMatch11, setResultMatch11] = useState(0);
  const [resultMatch12, setResultMatch12] = useState(0);
  const [resultMatch13, setResultMatch13] = useState(0);
  const [resultMatch14, setResultMatch14] = useState(0);
  const [resultMatch15, setResultMatch15] = useState(0);
  const [resultMatch16, setResultMatch16] = useState(0);
  const [resultMatch17, setResultMatch17] = useState(0);
  const [resultMatch18, setResultMatch18] = useState(0);
  const [resultMatch19, setResultMatch19] = useState(0);
  const [resultMatch20, setResultMatch20] = useState(0);
  const [resultMatch21, setResultMatch21] = useState(0);
  const [resultMatch22, setResultMatch22] = useState(0);
  const [resultMatch23, setResultMatch23] = useState(0);
  const [resultMatch24, setResultMatch24] = useState(0);

  const [hash, setHash] = useState("");

  const mintNft = async () => {
    setLoading(true);

    const price = 20 * 10 ** 6;
    const priceBigInt = ethers.BigNumber.from(price);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      //concat the 3 arrays
      const allResults = [
        resultMatch1,
        resultMatch2,
        resultMatch3,
        resultMatch4,
        resultMatch5,
        resultMatch6,
        resultMatch7,
        resultMatch8,
        resultMatch9,
        resultMatch10,
        resultMatch11,
        resultMatch12,
        resultMatch13,
        resultMatch14,
        resultMatch15,
        resultMatch16,
        resultMatch17,
        resultMatch18,
        resultMatch19,
        resultMatch20,
        resultMatch21,
        resultMatch22,
        resultMatch23,
        resultMatch24,
      ];

      //no result can be zero
      if (allResults.includes(0)) {
        toast.error("All results must be filled");
        setLoading(false);
        return;
      }

      let receipt;
      let estimateGas;
      try {
        // approve tokens
        const tokenContract = new ethers.Contract(
          tokenAddress,
          erc20,
          signer
        );

        try {
          estimateGas = await tokenContract.estimateGas.approve(
            nftPhaseOne,
            price
          );
        } catch (error) {
          console.error(error);
          toast.error("Erro ao estimar GAS");
          setLoading(false);
          return;
        }

        const transactionResponseToken = await tokenContract.approve(
          nftPhaseOne,
          price,
          {
            gasLimit: estimateGas,
          }
        );
        receipt = await transactionResponseToken.wait();
      } catch (error) {
        console.error(error);
        toast.error("Erro ao abrir contrato do token");
        setLoading(false);
        return;
      }

      // Mint
      try {
        const contract = new ethers.Contract(
          nftPhaseOne,
          olympicsTicket,
          signer
        );

        try {
          estimateGas = await contract.estimateGas.safeMint(game, allResults);
        } catch (error) {
          console.error(error);
          toast.error("Erro ao estimar GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.safeMint(game, allResults, {
          gasLimit: estimateGas,
        });
        receipt = await transactionResponse.wait();
      } catch (error) {
        console.error(error);
        toast.error("Erro ao enviar transação");
        setLoading(false);
        return;
      }
      setHash(receipt.transactionHash);
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <>
        <h4 className="text-muted">Group One</h4>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 1</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match1"
                id="team1"
                value="1"
                checked={resultMatch1 === 1}
                onChange={(e) => setResultMatch1(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team1">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[0]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match1"
                id="draw1"
                value="3"
                checked={resultMatch1 === 3}
                onChange={(e) => setResultMatch1(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw1">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match1"
                id="team2"
                value="2"
                checked={resultMatch1 === 2}
                onChange={(e) => setResultMatch1(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team2">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[1]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 2</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match2"
                id="team3"
                value="1"
                checked={resultMatch2 === 1}
                onChange={(e) => setResultMatch2(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team3">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[2]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match2"
                id="draw2"
                value="3"
                checked={resultMatch2 === 3}
                onChange={(e) => setResultMatch2(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw2">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match2"
                id="team4"
                value="2"
                checked={resultMatch2 === 2}
                onChange={(e) => setResultMatch2(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team4">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[3]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>

        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 3</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match3"
                id="team5"
                value="1"
                checked={resultMatch3 === 1}
                onChange={(e) => setResultMatch3(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team5">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[4]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match3"
                id="draw3"
                value="3"
                checked={resultMatch3 === 3}
                onChange={(e) => setResultMatch3(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw3">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match3"
                id="team6"
                value="2"
                checked={resultMatch3 === 2}
                onChange={(e) => setResultMatch3(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team6">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[5]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 4</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match4"
                id="team7"
                value="1"
                checked={resultMatch4 === 1}
                onChange={(e) => setResultMatch4(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team7">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[6]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match4"
                id="draw4"
                value="3"
                checked={resultMatch4 === 3}
                onChange={(e) => setResultMatch4(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw4">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match4"
                id="team8"
                value="2"
                checked={resultMatch4 === 2}
                onChange={(e) => setResultMatch4(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team8">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[7]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>

        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 5</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match5"
                id="team9"
                value="1"
                checked={resultMatch5 === 1}
                onChange={(e) => setResultMatch5(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team9">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[8]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match5"
                id="draw5"
                value="3"
                checked={resultMatch5 === 3}
                onChange={(e) => setResultMatch5(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw5">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match5"
                id="team10"
                value="2"
                checked={resultMatch5 === 2}
                onChange={(e) => setResultMatch5(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team10">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[9]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 6</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match6"
                id="team11"
                value="1"
                checked={resultMatch6 === 1}
                onChange={(e) => setResultMatch6(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team11">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[10]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match6"
                id="draw6"
                value="3"
                checked={resultMatch6 === 3}
                onChange={(e) => setResultMatch6(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw6">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match6"
                id="team12"
                value="2"
                checked={resultMatch6 === 2}
                onChange={(e) => setResultMatch6(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team12">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[11]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>

        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 7</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match7"
                id="team13"
                value="1"
                checked={resultMatch7 === 1}
                onChange={(e) => setResultMatch7(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team13">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[12]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match7"
                id="draw7"
                value="3"
                checked={resultMatch7 === 3}
                onChange={(e) => setResultMatch7(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw7">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match7"
                id="team14"
                value="2"
                checked={resultMatch7 === 2}
                onChange={(e) => setResultMatch7(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team14">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[13]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightblue", borderRadius: "10px" }}
          >
            <Label>Match 8</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match8"
                id="team15"
                value="1"
                checked={resultMatch8 === 1}
                onChange={(e) => setResultMatch8(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team15">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[14]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match8"
                id="draw8"
                value="3"
                checked={resultMatch8 === 3}
                onChange={(e) => setResultMatch8(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw8">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match8"
                id="team16"
                value="2"
                checked={resultMatch8 === 2}
                onChange={(e) => setResultMatch8(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team16">
                {group1?.teams && group1.teams.length > 0
                  ? group1.teams[15]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>

        <h4 className="text-muted mt-5">Group Two</h4>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 1</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match9"
                id="team17"
                value="1"
                checked={resultMatch9 === 1}
                onChange={(e) => setResultMatch9(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team17">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[0]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match9"
                id="draw9"
                value="3"
                checked={resultMatch9 === 3}
                onChange={(e) => setResultMatch9(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw9">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match9"
                id="team18"
                value="2"
                checked={resultMatch9 === 2}
                onChange={(e) => setResultMatch9(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team18">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[1]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 2</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match10"
                id="team19"
                value="1"
                checked={resultMatch10 === 1}
                onChange={(e) => setResultMatch10(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team19">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[2]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match10"
                id="draw10"
                value="3"
                checked={resultMatch10 === 3}
                onChange={(e) => setResultMatch10(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw10">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match10"
                id="team20"
                value="2"
                checked={resultMatch10 === 2}
                onChange={(e) => setResultMatch10(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team20">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[3]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 3</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match11"
                id="team21"
                value="1"
                checked={resultMatch11 === 1}
                onChange={(e) => setResultMatch11(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team21">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[4]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match11"
                id="draw11"
                value="3"
                checked={resultMatch11 === 3}
                onChange={(e) => setResultMatch11(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw11">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match11"
                id="team22"
                value="2"
                checked={resultMatch11 === 2}
                onChange={(e) => setResultMatch11(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team22">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[5]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 4</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match12"
                id="team23"
                value="1"
                checked={resultMatch12 === 1}
                onChange={(e) => setResultMatch12(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team23">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[6]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match12"
                id="draw12"
                value="3"
                checked={resultMatch12 === 3}
                onChange={(e) => setResultMatch12(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw12">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match12"
                id="team24"
                value="2"
                checked={resultMatch12 === 2}
                onChange={(e) => setResultMatch12(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team24">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[7]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 5</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match13"
                id="team25"
                value="1"
                checked={resultMatch13 === 1}
                onChange={(e) => setResultMatch13(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team25">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[8]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match13"
                id="draw13"
                value="3"
                checked={resultMatch13 === 3}
                onChange={(e) => setResultMatch13(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw13">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match13"
                id="team26"
                value="2"
                checked={resultMatch13 === 2}
                onChange={(e) => setResultMatch13(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team26">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[9]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 6</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match14"
                id="team27"
                value="1"
                checked={resultMatch14 === 1}
                onChange={(e) => setResultMatch14(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team27">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[10]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match14"
                id="draw14"
                value="3"
                checked={resultMatch14 === 3}
                onChange={(e) => setResultMatch14(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw14">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match14"
                id="team28"
                value="2"
                checked={resultMatch14 === 2}
                onChange={(e) => setResultMatch14(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team28">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[11]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 7</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match15"
                id="team29"
                value="1"
                checked={resultMatch15 === 1}
                onChange={(e) => setResultMatch15(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team29">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[12]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match15"
                id="draw15"
                value="3"
                checked={resultMatch15 === 3}
                onChange={(e) => setResultMatch15(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw15">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match15"
                id="team30"
                value="2"
                checked={resultMatch15 === 2}
                onChange={(e) => setResultMatch15(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team30">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[13]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightgreen", borderRadius: "10px" }}
          >
            <Label>Match 8</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match16"
                id="team31"
                value="1"
                checked={resultMatch16 === 1}
                onChange={(e) => setResultMatch16(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team31">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[14]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match16"
                id="draw16"
                value="3"
                checked={resultMatch16 === 3}
                onChange={(e) => setResultMatch16(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw16">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match16"
                id="team32"
                value="2"
                checked={resultMatch16 === 2}
                onChange={(e) => setResultMatch16(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team32">
                {group2?.teams && group2.teams.length > 0
                  ? group2.teams[15]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>

        <h4 className="text-muted mt-5">Group Three</h4>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 1</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match17"
                id="team33"
                value="1"
                checked={resultMatch17 === 1}
                onChange={(e) => setResultMatch17(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team33">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[0]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match17"
                id="draw17"
                value="3"
                checked={resultMatch17 === 3}
                onChange={(e) => setResultMatch17(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw17">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match17"
                id="team34"
                value="2"
                checked={resultMatch17 === 2}
                onChange={(e) => setResultMatch17(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team34">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[1]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 2</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match18"
                id="team35"
                value="1"
                checked={resultMatch18 === 1}
                onChange={(e) => setResultMatch18(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team35">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[2]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match18"
                id="draw18"
                value="3"
                checked={resultMatch18 === 3}
                onChange={(e) => setResultMatch18(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw18">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match18"
                id="team36"
                value="2"
                checked={resultMatch18 === 2}
                onChange={(e) => setResultMatch18(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team36">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[3]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 3</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match19"
                id="team37"
                value="1"
                checked={resultMatch19 === 1}
                onChange={(e) => setResultMatch19(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team37">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[4]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match19"
                id="draw19"
                value="3"
                checked={resultMatch19 === 3}
                onChange={(e) => setResultMatch19(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw19">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match19"
                id="team38"
                value="2"
                checked={resultMatch19 === 2}
                onChange={(e) => setResultMatch19(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team38">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[5]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 4</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match20"
                id="team39"
                value="1"
                checked={resultMatch20 === 1}
                onChange={(e) => setResultMatch20(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team39">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[6]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match20"
                id="draw20"
                value="3"
                checked={resultMatch20 === 3}
                onChange={(e) => setResultMatch20(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw20">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match20"
                id="team40"
                value="2"
                checked={resultMatch20 === 2}
                onChange={(e) => setResultMatch20(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team40">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[7]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 5</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match21"
                id="team41"
                value="1"
                checked={resultMatch21 === 1}
                onChange={(e) => setResultMatch21(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team41">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[8]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match21"
                id="draw21"
                value="3"
                checked={resultMatch21 === 3}
                onChange={(e) => setResultMatch21(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw21">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match21"
                id="team42"
                value="2"
                checked={resultMatch21 === 2}
                onChange={(e) => setResultMatch21(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team42">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[9]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 6</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match22"
                id="team43"
                value="1"
                checked={resultMatch22 === 1}
                onChange={(e) => setResultMatch22(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team43">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[10]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match22"
                id="draw22"
                value="3"
                checked={resultMatch22 === 3}
                onChange={(e) => setResultMatch22(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw22">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match22"
                id="team44"
                value="2"
                checked={resultMatch22 === 2}
                onChange={(e) => setResultMatch22(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team44">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[11]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 7</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match23"
                id="team45"
                value="1"
                checked={resultMatch23 === 1}
                onChange={(e) => setResultMatch23(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team45">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[12]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match23"
                id="draw23"
                value="3"
                checked={resultMatch23 === 3}
                onChange={(e) => setResultMatch23(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw23">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match23"
                id="team46"
                value="2"
                checked={resultMatch23 === 2}
                onChange={(e) => setResultMatch23(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team46">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[13]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="p-3"
            style={{ background: "lightcoral", borderRadius: "10px" }}
          >
            <Label>Match 8</Label>
            <div className="form-check form-check-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match24"
                id="team47"
                value="1"
                checked={resultMatch24 === 1}
                onChange={(e) => setResultMatch24(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team47">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[14]
                  : "Undefined"}
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match24"
                id="draw24"
                value="3"
                checked={resultMatch24 === 3}
                onChange={(e) => setResultMatch24(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="draw24">
                Draw
              </Label>
            </div>
            <div className="form-check form-radio-primary mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="match24"
                id="team48"
                value="2"
                checked={resultMatch24 === 2}
                onChange={(e) => setResultMatch24(parseInt(e.target.value))}
              />
              <Label className="form-check-label" htmlFor="team48">
                {group3?.teams && group3.teams.length > 0
                  ? group3.teams[15]
                  : "Undefined"}
              </Label>
            </div>
          </Col>
        </Row>
      </>
      <Row className="mt-5">
        <Col md={12}>
          <Button
            color="primary"
            className="btn btn-info"
            onClick={() => mintNft()}
            disabled={loading}
          >
            {loading && <Spinner size="sm" />} Mint NFT
          </Button>
          <Button
            color="danger"
            className="btn btn-info"
            onClick={() => setMintNft(false)}
            disabled={loading}
          >
            Back to Game Config
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CriarNftPhaseOne;
