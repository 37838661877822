import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
import FullScreenDropdown from "../Components/Common/FullScreenDropdown";
import NotificationDropdown from "../Components/Common/NotificationDropdown";
import ProfileDropdown from "../Components/Common/ProfileDropdown";

import { changeSidebarVisibility } from "../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import { APIClient } from "../helpers/api_helper";

const apiClient = new APIClient();

const Header = ({ headerClass }) => {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const selectDashboardData = createSelector(
    (state) => state.Layout,
    (sidebarVisibilitytype) => sidebarVisibilitytype.sidebarVisibilitytype
  );
  // Inside your component
  const sidebarVisibilitytype = useSelector(selectDashboardData);

  const [userData, setUserData] = useState(null);

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility("show"));

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (
      sidebarVisibilitytype === "show" &&
      (document.documentElement.getAttribute("data-layout") === "vertical" ||
        document.documentElement.getAttribute("data-layout") === "semibox")
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  const checkLastLogin = () => {
    const lastLoginTimestamp = localStorage.getItem("lastLogin");
    if (lastLoginTimestamp) {
      const lastLoginTime = new Date(parseInt(lastLoginTimestamp));
      const currentTime = new Date();
      const timeDiffInHours = (currentTime - lastLoginTime) / (1000 * 60 * 60);
  
      if (timeDiffInHours >= 1) {
        localStorage.removeItem("lastLogin");
        localStorage.removeItem("authUser");
        window.location.reload();
      }
    }
  };

  const fetchCliente = useCallback(async () => {
    setRefresh(true);

    const response = await apiClient.post("/user/refresh", {});

    if (response.sucesso) {
      const dados = response.dados;
      setUserData(dados);
      localStorage.setItem("authUser", JSON.stringify(dados));
      localStorage.setItem("lastLogin", new Date().getTime());
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }

    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setRefresh(false);
  }, []);
  
  useEffect(() => {
    const checkingLogin = async () => {
      await checkLastLogin();
      const storedData = localStorage.getItem("authUser");
      setUserData(JSON.parse(storedData));
    };
    checkingLogin();

    const handleDataUpdate = async () => {
      await fetchCliente();
    };
    window.addEventListener("localDataUpdated", handleDataUpdate);
    return () => {
      window.removeEventListener("localDataUpdated", handleDataUpdate);
    };
  }, []);
  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="35" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              <FullScreenDropdown />
              <NotificationDropdown />
              <ProfileDropdown userData={userData} />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
