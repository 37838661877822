import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { ethers } from "ethers";

import Group from "./Group";

const CriarJogoPhaseOne = ({
  getActiveGames,
  contrato,
  olympicsProxy,
  countries,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedGroup1, setSelectedGroup1] = useState([]);
  const [selectedGroup2, setSelectedGroup2] = useState([]);
  const [selectedGroup3, setSelectedGroup3] = useState([]);

  const [hash, setHash] = useState("");

  const convertToBytesData = (startTimeStamp, symbols1, symbols2, symbols3) => {
    for (let i = 0; i < 16; i++) {
      if (symbols1[i] === "" || symbols2[i] === "" || symbols3[i] === "") {
        toast.error("All groups must have 16 teams");
        return;
      }
    }

    const dataNewGame = ethers.utils.defaultAbiCoder.encode(
      ["uint256", "string[16]", "string[16]", "string[16]"],
      [startTimeStamp, symbols1, symbols2, symbols3]
    );
    return dataNewGame;
  };

  const createGame = async () => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const timestamp = Math.floor(Date.now() / 1000);
      // startTimestamp in 10 minutes
      const startTimestamp = timestamp + 600;

      const dataNewGame = convertToBytesData(
        startTimestamp,
        selectedGroup1,
        selectedGroup2,
        selectedGroup3
      );

      let receipt;
      try {
        const contract = new ethers.Contract(contrato, olympicsProxy, signer);
        let estimateGas;

        try {
          estimateGas = await contract.estimateGas.createGame(
            dataNewGame,
            timestamp
          );
        } catch (error) {
          console.error(error);
          toast.error("Erro ao estimar GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.createGame(
          dataNewGame,
          timestamp,
          {
            gasLimit: estimateGas,
          }
        );
        receipt = await transactionResponse.wait();
      } catch (error) {
        console.error(error);
        toast.error("Erro ao enviar transação");
        setLoading(false);
        return;
      }
      setHash(receipt.transactionHash);
      await getActiveGames();
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAvailableCountries = (exception, selected) => {
    return countries.filter((country) => {
      return !selected.includes(country) || country === exception;
    });
  };

  return (
    <React.Fragment>
      <Group
        name="First Group"
        selectedTeams={selectedGroup1}
        setSelectedTeams={setSelectedGroup1}
        getAvailableCountries={getAvailableCountries}
        group={1}
      />
      <Group
        name="Second Group"
        selectedTeams={selectedGroup2}
        setSelectedTeams={setSelectedGroup2}
        getAvailableCountries={getAvailableCountries}
        group={2}
      />
      <Group
        name="Third Group"
        selectedTeams={selectedGroup3}
        setSelectedTeams={setSelectedGroup3}
        getAvailableCountries={getAvailableCountries}
        group={3}
      />
      <Row className="mt-5">
        <Col md={12}>
          <Button
            color="primary"
            className="btn btn-info"
            onClick={() => createGame()}
            disabled={loading}
          >
            {loading && <Spinner size="sm" />} Create Game
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CriarJogoPhaseOne;
