import React, { useState, useEffect } from "react";
import { Col, Label, Row, Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import CriarNftPhaseOne from "./CriarNftPhaseOne";

const EditarJogoPhaseOne = ({
  game,
  contrato,
  olympicsProxy,
  countries,
  setGame,
}) => {
  const [loading, setLoading] = useState(false);
  const [mintNft, setMintNft] = useState(false);
  const [nftId, setNftId] = useState(0);

  //24 matches
  const [resultMatch1, setResultMatch1] = useState(0);
  const [resultMatch2, setResultMatch2] = useState(0);
  const [resultMatch3, setResultMatch3] = useState(0);
  const [resultMatch4, setResultMatch4] = useState(0);
  const [resultMatch5, setResultMatch5] = useState(0);
  const [resultMatch6, setResultMatch6] = useState(0);
  const [resultMatch7, setResultMatch7] = useState(0);
  const [resultMatch8, setResultMatch8] = useState(0);
  const [resultMatch9, setResultMatch9] = useState(0);
  const [resultMatch10, setResultMatch10] = useState(0);
  const [resultMatch11, setResultMatch11] = useState(0);
  const [resultMatch12, setResultMatch12] = useState(0);
  const [resultMatch13, setResultMatch13] = useState(0);
  const [resultMatch14, setResultMatch14] = useState(0);
  const [resultMatch15, setResultMatch15] = useState(0);
  const [resultMatch16, setResultMatch16] = useState(0);
  const [resultMatch17, setResultMatch17] = useState(0);
  const [resultMatch18, setResultMatch18] = useState(0);
  const [resultMatch19, setResultMatch19] = useState(0);
  const [resultMatch20, setResultMatch20] = useState(0);
  const [resultMatch21, setResultMatch21] = useState(0);
  const [resultMatch22, setResultMatch22] = useState(0);
  const [resultMatch23, setResultMatch23] = useState(0);
  const [resultMatch24, setResultMatch24] = useState(0);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [activated, setActivated] = useState(0);

  const [group1, setGroup1] = useState({});
  const [group2, setGroup2] = useState({});
  const [group3, setGroup3] = useState({});

  const [hash, setHash] = useState("");

  const convertFromBytesData = (data) => {
    const decodedGameData = ethers.utils.defaultAbiCoder.decode(
      ["bytes", "bytes", "bytes", "uint256", "uint256", "uint8"],
      data
    );
    setStart(decodedGameData[3]);
    setEnd(decodedGameData[4]);
    setActivated(decodedGameData[5]);

    const decodedGroup1 = ethers.utils.defaultAbiCoder.decode(
      ["string[16]", "uint8[8]", "uint256", "uint256"],
      decodedGameData[0]
    );

    setGroup1({
      teams: decodedGroup1[0],
      results: decodedGroup1[1],
      start: decodedGroup1[2],
      end: decodedGroup1[3],
    });
    console.log("Group 1: ", decodedGroup1);

    const decodedGroup2 = ethers.utils.defaultAbiCoder.decode(
      ["string[16]", "uint8[8]", "uint256", "uint256"],
      decodedGameData[1]
    );

    setGroup2({
      teams: decodedGroup2[0],
      results: decodedGroup2[1],
      start: decodedGroup2[2],
      end: decodedGroup2[3],
    });

    const decodedGroup3 = ethers.utils.defaultAbiCoder.decode(
      ["string[16]", "uint8[8]", "uint256", "uint256"],
      decodedGameData[2]
    );

    setGroup3({
      teams: decodedGroup3[0],
      results: decodedGroup3[1],
      start: decodedGroup3[2],
      end: decodedGroup3[3],
    });

    setResultMatch1(decodedGroup1[1][0]);
    setResultMatch2(decodedGroup1[1][1]);
    setResultMatch3(decodedGroup1[1][2]);
    setResultMatch4(decodedGroup1[1][3]);
    setResultMatch5(decodedGroup1[1][4]);
    setResultMatch6(decodedGroup1[1][5]);
    setResultMatch7(decodedGroup1[1][6]);
    setResultMatch8(decodedGroup1[1][7]);
    setResultMatch9(decodedGroup2[1][0]);
    setResultMatch10(decodedGroup2[1][1]);
    setResultMatch11(decodedGroup2[1][2]);
    setResultMatch12(decodedGroup2[1][3]);
    setResultMatch13(decodedGroup2[1][4]);
    setResultMatch14(decodedGroup2[1][5]);
    setResultMatch15(decodedGroup2[1][6]);
    setResultMatch16(decodedGroup2[1][7]);
    setResultMatch17(decodedGroup3[1][0]);
    setResultMatch18(decodedGroup3[1][1]);
    setResultMatch19(decodedGroup3[1][2]);
    setResultMatch20(decodedGroup3[1][3]);
    setResultMatch21(decodedGroup3[1][4]);
    setResultMatch22(decodedGroup3[1][5]);
    setResultMatch23(decodedGroup3[1][6]);
    setResultMatch24(decodedGroup3[1][7]);
  };

  const getGameFullData = async (game) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      try {
        const contract = new ethers.Contract(contrato, olympicsProxy, signer);
        const gameData = await contract.getGameFullData(game);
        convertFromBytesData(gameData);
      } catch (error) {
        console.error("Error on getGameFullData: ", error);
      }
    } catch (error) {
      console.error("Error on getGameFullData: ", error);
    } finally {
      setLoading(false);
    }
  };

  const updateGame = async (game, match, group, result) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const timestamp = Math.floor(Date.now() / 1000);

      try {
        const contract = new ethers.Contract(contrato, olympicsProxy, signer);
        let estimateGas;
        let receipt;

        try {
          estimateGas = await contract.estimateGas.setMatchResult(
            timestamp,
            game,
            group,
            match,
            result
          );
        } catch (error) {
          console.error(error);
          toast.error("Error on estimating GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.setMatchResult(
          timestamp,
          game,
          group,
          match,
          result,
          {
            gasLimit: estimateGas,
          }
        );
        receipt = await transactionResponse.wait();
        setHash(receipt.transactionHash);
      } catch (error) {
        console.error(error);
        toast.error("Error on updateGame");
        setLoading(false);
        return;
      }
      await getGameFullData(game);
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    }

    setGroup1({});
    setGroup2({});
    setGroup3({});

    setResultMatch1(0);
    setResultMatch2(0);
    setResultMatch3(0);
    setResultMatch4(0);
    setResultMatch5(0);
    setResultMatch6(0);
    setResultMatch7(0);
    setResultMatch8(0);
    setResultMatch9(0);
    setResultMatch10(0);
    setResultMatch11(0);
    setResultMatch12(0);
    setResultMatch13(0);
    setResultMatch14(0);
    setResultMatch15(0);
    setResultMatch16(0);
    setResultMatch17(0);
    setResultMatch18(0);
    setResultMatch19(0);
    setResultMatch20(0);
    setResultMatch21(0);
    setResultMatch22(0);
    setResultMatch23(0);
    setResultMatch24(0);

    await getGameFullData(game);
    setLoading(false);
  };

  const activateGame = async (game) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      try {
        const contract = new ethers.Contract(contrato, olympicsProxy, signer);
        let estimateGas;
        let receipt;

        const gameBigNumber = ethers.BigNumber.from(game);

        try {
          estimateGas = await contract.estimateGas.activateGame(gameBigNumber);
        } catch (error) {
          console.error(error);
          toast.error("Error on estimating GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.activateGame(gameBigNumber, {
          gasLimit: estimateGas,
        });
        receipt = await transactionResponse.wait();
      } catch (error) {
        console.error(error);
        toast.error("Error on activateGame");
        setLoading(false);
        return;
      }
      await getGameFullData(game);
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getGameData = async (game) => {
      await getGameFullData(game);
    };

    if (game) getGameData(game);
  }, [game]);

  return (
    <React.Fragment>
      {game && (
        <>
          <h2>
            {mintNft ? "Mint Ticket for" : "Edit"} Game {game}
          </h2>
        </>
      )}
      {loading ? (
        <Row className="justify-content-center">
          <Spinner />
        </Row>
      ) : activated === 0 && mintNft ? (
        <CriarNftPhaseOne
          game={game}
          setMintNft={setMintNft}
          setNftId={setNftId}
          nftId={nftId}
          group1={group1}
          group2={group2}
          group3={group3}
        />
      ) : activated === 0 ? (
        <>
          <h5>Game not activated</h5>
          <Row className="mt-5">
            <Col md={12}>
              <Button
                color="primary"
                className="btn btn-success"
                onClick={() => activateGame(game)}
                disabled={loading}
              >
                {loading && <Spinner size="sm" />} Activate Game
              </Button>
              <Button
                color="secondary"
                className="btn btn-info ms-2"
                onClick={() => setMintNft(true)}
                disabled={loading}
              >
                {loading && <Spinner size="sm" />} Mint NFT
              </Button>
            </Col>
          </Row>
        </>
      ) : activated === 1 ? (
        <>
          <h4 className="text-muted">Group One</h4>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 1</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team1"
                  value="1"
                  checked={resultMatch1 === 1}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team1">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[0]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="draw1"
                  value="3"
                  checked={resultMatch1 === 3}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw1">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match1"
                  id="team2"
                  value="2"
                  checked={resultMatch1 === 2}
                  onChange={(e) => setResultMatch1(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team2">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[1]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[0] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 0, 0, resultMatch1)}
                  disabled={
                    loading ||
                    resultMatch1 === 0 ||
                    (group1?.results ? group1.results[0] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[0] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 2</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team3"
                  value="1"
                  checked={resultMatch2 === 1}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team3">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[2]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="draw2"
                  value="3"
                  checked={resultMatch2 === 3}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw2">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match2"
                  id="team4"
                  value="2"
                  checked={resultMatch2 === 2}
                  onChange={(e) => setResultMatch2(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team4">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[3]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[1] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 1, 0, resultMatch2)}
                  disabled={
                    loading ||
                    resultMatch2 === 0 ||
                    (group1?.results ? group1.results[1] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[1] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 3</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match3"
                  id="team5"
                  value="1"
                  checked={resultMatch3 === 1}
                  onChange={(e) => setResultMatch3(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team5">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[4]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match3"
                  id="draw3"
                  value="3"
                  checked={resultMatch3 === 3}
                  onChange={(e) => setResultMatch3(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw3">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match3"
                  id="team6"
                  value="2"
                  checked={resultMatch3 === 2}
                  onChange={(e) => setResultMatch3(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team6">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[5]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[2] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 2, 0, resultMatch3)}
                  disabled={
                    loading ||
                    resultMatch3 === 0 ||
                    (group1?.results ? group1.results[2] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[2] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 4</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match4"
                  id="team7"
                  value="1"
                  checked={resultMatch4 === 1}
                  onChange={(e) => setResultMatch4(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team7">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[6]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match4"
                  id="draw4"
                  value="3"
                  checked={resultMatch4 === 3}
                  onChange={(e) => setResultMatch4(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw4">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match4"
                  id="team8"
                  value="2"
                  checked={resultMatch4 === 2}
                  onChange={(e) => setResultMatch4(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team8">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[7]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[3] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 3, 0, resultMatch4)}
                  disabled={
                    loading ||
                    resultMatch4 === 0 ||
                    (group1?.results ? group1.results[3] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[3] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 5</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match5"
                  id="team9"
                  value="1"
                  checked={resultMatch5 === 1}
                  onChange={(e) => setResultMatch5(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team9">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[8]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match5"
                  id="draw5"
                  value="3"
                  checked={resultMatch5 === 3}
                  onChange={(e) => setResultMatch5(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw5">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match5"
                  id="team10"
                  value="2"
                  checked={resultMatch5 === 2}
                  onChange={(e) => setResultMatch5(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team10">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[9]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[4] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 4, 0, resultMatch5)}
                  disabled={
                    loading ||
                    resultMatch5 === 0 ||
                    (group1?.results ? group1.results[4] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[4] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 6</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match6"
                  id="team11"
                  value="1"
                  checked={resultMatch6 === 1}
                  onChange={(e) => setResultMatch6(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team11">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[10]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match6"
                  id="draw6"
                  value="3"
                  checked={resultMatch6 === 3}
                  onChange={(e) => setResultMatch6(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw6">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match6"
                  id="team12"
                  value="2"
                  checked={resultMatch6 === 2}
                  onChange={(e) => setResultMatch6(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team12">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[11]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[5] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 5, 0, resultMatch6)}
                  disabled={
                    loading ||
                    resultMatch6 === 0 ||
                    (group1?.results ? group1.results[5] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[5] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 7</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match7"
                  id="team13"
                  value="1"
                  checked={resultMatch7 === 1}
                  onChange={(e) => setResultMatch7(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team13">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[12]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match7"
                  id="draw7"
                  value="3"
                  checked={resultMatch7 === 3}
                  onChange={(e) => setResultMatch7(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw7">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match7"
                  id="team14"
                  value="2"
                  checked={resultMatch7 === 2}
                  onChange={(e) => setResultMatch7(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team14">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[13]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[6] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 6, 0, resultMatch7)}
                  disabled={
                    loading ||
                    resultMatch7 === 0 ||
                    (group1?.results ? group1.results[6] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[6] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightblue", borderRadius: "10px" }}
            >
              <Label>Match 8</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match8"
                  id="team15"
                  value="1"
                  checked={resultMatch8 === 1}
                  onChange={(e) => setResultMatch8(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team15">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[14]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match8"
                  id="draw8"
                  value="3"
                  checked={resultMatch8 === 3}
                  onChange={(e) => setResultMatch8(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw8">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match8"
                  id="team16"
                  value="2"
                  checked={resultMatch8 === 2}
                  onChange={(e) => setResultMatch8(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team16">
                  {group1?.teams && group1.teams.length > 0
                    ? group1.teams[15]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group1?.results ? group1.results[7] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 7, 0, resultMatch8)}
                  disabled={
                    loading ||
                    resultMatch8 === 0 ||
                    (group1?.results ? group1.results[7] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group1?.results ? group1.results[7] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>

          <h4 className="text-muted mt-5">Group Two</h4>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 1</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match9"
                  id="team17"
                  value="1"
                  checked={resultMatch9 === 1}
                  onChange={(e) => setResultMatch9(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team17">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[0]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match9"
                  id="draw9"
                  value="3"
                  checked={resultMatch9 === 3}
                  onChange={(e) => setResultMatch9(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw9">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match9"
                  id="team18"
                  value="2"
                  checked={resultMatch9 === 2}
                  onChange={(e) => setResultMatch9(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team18">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[1]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[0] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 0, 1, resultMatch9)}
                  disabled={
                    loading ||
                    resultMatch9 === 0 ||
                    (group2?.results ? group2.results[0] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[0] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 2</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match10"
                  id="team19"
                  value="1"
                  checked={resultMatch10 === 1}
                  onChange={(e) => setResultMatch10(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team19">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[2]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match10"
                  id="draw10"
                  value="3"
                  checked={resultMatch10 === 3}
                  onChange={(e) => setResultMatch10(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw10">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match10"
                  id="team20"
                  value="2"
                  checked={resultMatch10 === 2}
                  onChange={(e) => setResultMatch10(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team20">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[3]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[1] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 1, 1, resultMatch10)}
                  disabled={
                    loading ||
                    resultMatch10 === 0 ||
                    (group2?.results ? group2.results[1] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[1] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 3</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match11"
                  id="team21"
                  value="1"
                  checked={resultMatch11 === 1}
                  onChange={(e) => setResultMatch11(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team21">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[4]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match11"
                  id="draw11"
                  value="3"
                  checked={resultMatch11 === 3}
                  onChange={(e) => setResultMatch11(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw11">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match11"
                  id="team22"
                  value="2"
                  checked={resultMatch11 === 2}
                  onChange={(e) => setResultMatch11(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team22">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[5]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[2] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 2, 1, resultMatch11)}
                  disabled={
                    loading ||
                    resultMatch11 === 0 ||
                    (group2?.results ? group2.results[2] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[2] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 4</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match12"
                  id="team23"
                  value="1"
                  checked={resultMatch12 === 1}
                  onChange={(e) => setResultMatch12(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team23">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[6]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match12"
                  id="draw12"
                  value="3"
                  checked={resultMatch12 === 3}
                  onChange={(e) => setResultMatch12(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw12">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match12"
                  id="team24"
                  value="2"
                  checked={resultMatch12 === 2}
                  onChange={(e) => setResultMatch12(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team24">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[7]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[3] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 3, 1, resultMatch12)}
                  disabled={
                    loading ||
                    resultMatch12 === 0 ||
                    (group2?.results ? group2.results[3] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[3] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 5</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match13"
                  id="team25"
                  value="1"
                  checked={resultMatch13 === 1}
                  onChange={(e) => setResultMatch13(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team25">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[8]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match13"
                  id="draw13"
                  value="3"
                  checked={resultMatch13 === 3}
                  onChange={(e) => setResultMatch13(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw13">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match13"
                  id="team26"
                  value="2"
                  checked={resultMatch13 === 2}
                  onChange={(e) => setResultMatch13(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team26">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[9]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[4] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 4, 1, resultMatch13)}
                  disabled={
                    loading ||
                    resultMatch13 === 0 ||
                    (group2?.results ? group2.results[4] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[4] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 6</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match14"
                  id="team27"
                  value="1"
                  checked={resultMatch14 === 1}
                  onChange={(e) => setResultMatch14(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team27">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[10]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match14"
                  id="draw14"
                  value="3"
                  checked={resultMatch14 === 3}
                  onChange={(e) => setResultMatch14(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw14">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match14"
                  id="team28"
                  value="2"
                  checked={resultMatch14 === 2}
                  onChange={(e) => setResultMatch14(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team28">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[11]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[5] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 5, 1, resultMatch14)}
                  disabled={
                    loading ||
                    resultMatch14 === 0 ||
                    (group2?.results ? group2.results[5] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[5] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 7</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match15"
                  id="team29"
                  value="1"
                  checked={resultMatch15 === 1}
                  onChange={(e) => setResultMatch15(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team29">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[12]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match15"
                  id="draw15"
                  value="3"
                  checked={resultMatch15 === 3}
                  onChange={(e) => setResultMatch15(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw15">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match15"
                  id="team30"
                  value="2"
                  checked={resultMatch15 === 2}
                  onChange={(e) => setResultMatch15(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team30">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[13]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[6] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 6, 1, resultMatch15)}
                  disabled={
                    loading ||
                    resultMatch15 === 0 ||
                    (group2?.results ? group2.results[6] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[6] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightgreen", borderRadius: "10px" }}
            >
              <Label>Match 8</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match16"
                  id="team31"
                  value="1"
                  checked={resultMatch16 === 1}
                  onChange={(e) => setResultMatch16(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team31">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[14]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match16"
                  id="draw16"
                  value="3"
                  checked={resultMatch16 === 3}
                  onChange={(e) => setResultMatch16(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw16">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match16"
                  id="team32"
                  value="2"
                  checked={resultMatch16 === 2}
                  onChange={(e) => setResultMatch16(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team32">
                  {group2?.teams && group2.teams.length > 0
                    ? group2.teams[15]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group2?.results ? group2.results[7] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 7, 1, resultMatch16)}
                  disabled={
                    loading ||
                    resultMatch16 === 0 ||
                    (group2?.results ? group2.results[7] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group2?.results ? group2.results[7] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>

          <h4 className="text-muted mt-5">Group Three</h4>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 1</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match17"
                  id="team33"
                  value="1"
                  checked={resultMatch17 === 1}
                  onChange={(e) => setResultMatch17(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team33">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[0]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match17"
                  id="draw17"
                  value="3"
                  checked={resultMatch17 === 3}
                  onChange={(e) => setResultMatch17(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw17">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match17"
                  id="team34"
                  value="2"
                  checked={resultMatch17 === 2}
                  onChange={(e) => setResultMatch17(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team34">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[1]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[0] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 0, 2, resultMatch17)}
                  disabled={
                    loading ||
                    resultMatch17 === 0 ||
                    (group3?.results ? group3.results[0] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[0] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 2</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match18"
                  id="team35"
                  value="1"
                  checked={resultMatch18 === 1}
                  onChange={(e) => setResultMatch18(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team35">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[2]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match18"
                  id="draw18"
                  value="3"
                  checked={resultMatch18 === 3}
                  onChange={(e) => setResultMatch18(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw18">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match18"
                  id="team36"
                  value="2"
                  checked={resultMatch18 === 2}
                  onChange={(e) => setResultMatch18(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team36">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[3]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[1] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 1, 2, resultMatch18)}
                  disabled={
                    loading ||
                    resultMatch18 === 0 ||
                    (group3?.results ? group3.results[1] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[1] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 3</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match19"
                  id="team37"
                  value="1"
                  checked={resultMatch19 === 1}
                  onChange={(e) => setResultMatch19(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team37">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[4]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match19"
                  id="draw19"
                  value="3"
                  checked={resultMatch19 === 3}
                  onChange={(e) => setResultMatch19(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw19">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match19"
                  id="team38"
                  value="2"
                  checked={resultMatch19 === 2}
                  onChange={(e) => setResultMatch19(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team38">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[5]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[2] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 2, 2, resultMatch19)}
                  disabled={
                    loading ||
                    resultMatch19 === 0 ||
                    (group3?.results ? group3.results[2] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[2] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 4</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match20"
                  id="team39"
                  value="1"
                  checked={resultMatch20 === 1}
                  onChange={(e) => setResultMatch20(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team39">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[6]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match20"
                  id="draw20"
                  value="3"
                  checked={resultMatch20 === 3}
                  onChange={(e) => setResultMatch20(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw20">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match20"
                  id="team40"
                  value="2"
                  checked={resultMatch20 === 2}
                  onChange={(e) => setResultMatch20(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team40">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[7]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[3] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 3, 2, resultMatch20)}
                  disabled={
                    loading ||
                    resultMatch20 === 0 ||
                    (group3?.results ? group3.results[3] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[3] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 5</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match21"
                  id="team41"
                  value="1"
                  checked={resultMatch21 === 1}
                  onChange={(e) => setResultMatch21(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team41">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[8]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match21"
                  id="draw21"
                  value="3"
                  checked={resultMatch21 === 3}
                  onChange={(e) => setResultMatch21(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw21">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match21"
                  id="team42"
                  value="2"
                  checked={resultMatch21 === 2}
                  onChange={(e) => setResultMatch21(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team42">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[9]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[4] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 4, 2, resultMatch21)}
                  disabled={
                    loading ||
                    resultMatch21 === 0 ||
                    (group3?.results ? group3.results[4] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[4] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 6</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match22"
                  id="team43"
                  value="1"
                  checked={resultMatch22 === 1}
                  onChange={(e) => setResultMatch22(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team43">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[10]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match22"
                  id="draw22"
                  value="3"
                  checked={resultMatch22 === 3}
                  onChange={(e) => setResultMatch22(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw22">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match22"
                  id="team44"
                  value="2"
                  checked={resultMatch22 === 2}
                  onChange={(e) => setResultMatch22(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team44">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[11]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[5] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 5, 2, resultMatch22)}
                  disabled={
                    loading ||
                    resultMatch22 === 0 ||
                    (group3?.results ? group3.results[5] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[5] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 7</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match23"
                  id="team45"
                  value="1"
                  checked={resultMatch23 === 1}
                  onChange={(e) => setResultMatch23(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team45">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[12]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match23"
                  id="draw23"
                  value="3"
                  checked={resultMatch23 === 3}
                  onChange={(e) => setResultMatch23(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw23">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match23"
                  id="team46"
                  value="2"
                  checked={resultMatch23 === 2}
                  onChange={(e) => setResultMatch23(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team46">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[13]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[6] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 6, 2, resultMatch23)}
                  disabled={
                    loading ||
                    resultMatch23 === 0 ||
                    (group3?.results ? group3.results[6] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[6] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="p-3"
              style={{ background: "lightcoral", borderRadius: "10px" }}
            >
              <Label>Match 8</Label>
              <div className="form-check form-check-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match24"
                  id="team47"
                  value="1"
                  checked={resultMatch24 === 1}
                  onChange={(e) => setResultMatch24(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team47">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[14]
                    : "Undefined"}
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match24"
                  id="draw24"
                  value="3"
                  checked={resultMatch24 === 3}
                  onChange={(e) => setResultMatch24(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="draw24">
                  Draw
                </Label>
              </div>
              <div className="form-check form-radio-primary mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="match24"
                  id="team48"
                  value="2"
                  checked={resultMatch24 === 2}
                  onChange={(e) => setResultMatch24(parseInt(e.target.value))}
                />
                <Label className="form-check-label" htmlFor="team48">
                  {group3?.teams && group3.teams.length > 0
                    ? group3.teams[15]
                    : "Undefined"}
                </Label>
              </div>
              <Row className="p-3">
                <Button
                  color="primary"
                  className={`btn btn-${
                    (group3?.results ? group3.results[7] : 0) !== 0
                      ? "info"
                      : "primary"
                  }`}
                  onClick={() => updateGame(game, 7, 2, resultMatch24)}
                  disabled={
                    loading ||
                    resultMatch24 === 0 ||
                    (group3?.results ? group3.results[7] : 0) !== 0
                  }
                >
                  {loading && <Spinner size="sm" />}{" "}
                  {(group3?.results ? group3.results[7] : 0) !== 0
                    ? "Decided"
                    : "Update Game"}
                </Button>
              </Row>
            </Col>
          </Row>
          {end > 0 && (
            <Row className="mt-3 p-3" style={{ textAlign: "left" }}>
              <Button
                color="primary"
                className="btn btn-primary"
                onClick={() => setGame(null)}
              >
                New Game
              </Button>
            </Row>
          )}
        </>
      ) : (
        <>
          <h5>Dados finais do jogo</h5>
        </>
      )}
    </React.Fragment>
  );
};

export default EditarJogoPhaseOne;
