export const olympicsProxy = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_gamesHubAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_executorAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_lastGameId",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "_gameName",
        "type": "string"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "daysToClaimPrize",
        "type": "uint8"
      }
    ],
    "name": "DaysToClaimPrizeChanged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      }
    ],
    "name": "GameActivated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      }
    ],
    "name": "GameCreated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      }
    ],
    "name": "GameFinished",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "matchId",
        "type": "uint8"
      },
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "result",
        "type": "uint8"
      }
    ],
    "name": "MatchDecided",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "bool",
        "name": "paused",
        "type": "bool"
      }
    ],
    "name": "Paused",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "lastTimeStamp",
        "type": "uint256"
      }
    ],
    "name": "UpdatePerformed",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_gameIndex",
        "type": "uint256"
      }
    ],
    "name": "activateGame",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint8",
        "name": "_daysToClaimPrize",
        "type": "uint8"
      }
    ],
    "name": "changeDaysToClaimPrize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_dataNewGame",
        "type": "bytes"
      },
      {
        "internalType": "uint256",
        "name": "_lastTimeStamp",
        "type": "uint256"
      }
    ],
    "name": "createGame",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "createNewGames",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "daysToClaimPrize",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "executionAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "gameName",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "gamesHub",
    "outputs": [
      {
        "internalType": "contract IGamesHub",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getActiveGames",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      }
    ],
    "name": "getFinalResult",
    "outputs": [
      {
        "internalType": "uint8[24]",
        "name": "",
        "type": "uint8[24]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_gameId",
        "type": "uint256"
      }
    ],
    "name": "getGameContract",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      }
    ],
    "name": "getGameFullData",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      }
    ],
    "name": "getGameStatus",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "status",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint8",
        "name": "group",
        "type": "uint8"
      }
    ],
    "name": "getGroupData",
    "outputs": [
      {
        "internalType": "uint256[16]",
        "name": "teams",
        "type": "uint256[16]"
      },
      {
        "internalType": "uint8[8]",
        "name": "results",
        "type": "uint8[8]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint8",
        "name": "group",
        "type": "uint8"
      }
    ],
    "name": "getGroupFullData",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_symbol",
        "type": "string"
      }
    ],
    "name": "getTeamId",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "teamIndex",
        "type": "uint256"
      }
    ],
    "name": "getTeamSymbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_symbols",
        "type": "bytes"
      }
    ],
    "name": "getTeamsIds",
    "outputs": [
      {
        "internalType": "uint256[16]",
        "name": "",
        "type": "uint256[16]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_teams",
        "type": "bytes"
      }
    ],
    "name": "getTeamsSymbols",
    "outputs": [
      {
        "internalType": "string[16]",
        "name": "",
        "type": "string[16]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minActiveGames",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "paused",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_active",
        "type": "bool"
      }
    ],
    "name": "setCreateNewGames",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_executionAddress",
        "type": "address"
      }
    ],
    "name": "setExecutionAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_gameId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_gameAddress",
        "type": "address"
      }
    ],
    "name": "setGameContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_lastTimeStamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_gameIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint8",
        "name": "_group",
        "type": "uint8"
      },
      {
        "internalType": "uint8",
        "name": "_match",
        "type": "uint8"
      },
      {
        "internalType": "uint8",
        "name": "_result",
        "type": "uint8"
      }
    ],
    "name": "setMatchResult",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint8",
        "name": "_minActiveGames",
        "type": "uint8"
      }
    ],
    "name": "setMinConcurrentGames",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_paused",
        "type": "bool"
      }
    ],
    "name": "setPaused",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalGames",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];