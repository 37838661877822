import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Button, Spinner } from "reactstrap";

const Group = ({
  name,
  selectedTeams,
  setSelectedTeams,
  getAvailableCountries,
  group,
}) => {
  const [loading, setLoading] = useState(false);
  // 16 teams
  const [team1, setTeam1] = useState(
    group === 1
      ? "Uzbekistan"
      : group === 2
      ? "Dominican Republic"
      : "Dominican Republic"
  );
  const [team2, setTeam2] = useState(
    group === 1 ? "Spain" : group === 2 ? "Spain" : "Uzbekistan"
  );
  const [team3, setTeam3] = useState(
    group === 1 ? "Argentina" : group === 2 ? "Argentina" : "Spain"
  );
  const [team4, setTeam4] = useState(
    group === 1 ? "Morocco" : group === 2 ? "Iraq" : "Egypt"
  );
  const [team5, setTeam5] = useState(
    group === 1 ? "Guinea" : group === 2 ? "Uzbekistan" : "Ukraine"
  );
  const [team6, setTeam6] = useState(
    group === 1 ? "New Zealand" : group === 2 ? "Egypt" : "Argentina"
  );
  const [team7, setTeam7] = useState(
    group === 1 ? "Egypt" : group === 2 ? "Ukraine" : "Morocco"
  );
  const [team8, setTeam8] = useState(
    group === 1 ? "Dominican Republic" : group === 2 ? "Morocco" : "Iraq"
  );
  const [team9, setTeam9] = useState(
    group === 1 ? "Iraq" : group === 2 ? "New Zealand" : "New Zealand"
  );
  const [team10, setTeam10] = useState(
    group === 1 ? "Ukraine" : group === 2 ? "USA" : "France"
  );
  const [team11, setTeam11] = useState(
    group === 1 ? "Japan" : group === 2 ? "Israel" : "USA"
  );
  const [team12, setTeam12] = useState(
    group === 1 ? "Paraguay" : group === 2 ? "Paraguay" : "Guinea"
  );
  const [team13, setTeam13] = useState(
    group === 1 ? "France" : group === 2 ? "France" : "Israel"
  );
  const [team14, setTeam14] = useState(
    group === 1 ? "USA" : group === 2 ? "Guinea" : "Japan"
  );
  const [team15, setTeam15] = useState(
    group === 1 ? "Mali" : group === 2 ? "Japan" : "Paraguay"
  );
  const [team16, setTeam16] = useState(
    group === 1 ? "Israel" : group === 2 ? "Mali" : "Mali"
  );

  useEffect(() => {
    const teams = [
      team1,
      team2,
      team3,
      team4,
      team5,
      team6,
      team7,
      team8,
      team9,
      team10,
      team11,
      team12,
      team13,
      team14,
      team15,
      team16,
    ];
    setSelectedTeams(teams.filter((team) => team !== ""));
  }, [
    team1,
    team2,
    team3,
    team4,
    team5,
    team6,
    team7,
    team8,
    team9,
    team10,
    team11,
    team12,
    team13,
    team14,
    team15,
    team16,
  ]);

  return (
    <React.Fragment>
      <h3 className="text-center mt-5">{name}</h3>
      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
          background:
            name === "First Group"
              ? "lightblue"
              : name === "Third Group"
              ? "lightcoral"
              : "lightgreen",
          borderRadius: "10px",
        }}
      >
        <Col md={12}>
          <Label>Match 1</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team1"
            placeholder="Team 1"
            value={team1}
            onChange={(e) => setTeam1(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team1, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team2"
            placeholder="Team 2"
            value={team2}
            onChange={(e) => setTeam2(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team2, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
        }}
      >
        <Col md={12}>
          <Label>Match 2</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team3"
            placeholder="Team 3"
            value={team3}
            onChange={(e) => setTeam3(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team3, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team4"
            placeholder="Team 4"
            value={team4}
            onChange={(e) => setTeam4(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team4, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
          background:
            name === "First Group"
              ? "lightblue"
              : name === "Third Group"
              ? "lightcoral"
              : "lightgreen",
          borderRadius: "10px",
        }}
      >
        <Col md={12}>
          <Label>Match 3</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team5"
            placeholder="Team 5"
            value={team5}
            onChange={(e) => setTeam5(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team5, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team6"
            placeholder="Team 6"
            value={team6}
            onChange={(e) => setTeam6(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team6, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
        }}
      >
        <Col md={12}>
          <Label>Match 4</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team7"
            placeholder="Team 7"
            value={team7}
            onChange={(e) => setTeam7(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team7, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team8"
            placeholder="Team 8"
            value={team8}
            onChange={(e) => setTeam8(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team8, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
          background:
            name === "First Group"
              ? "lightblue"
              : name === "Third Group"
              ? "lightcoral"
              : "lightgreen",
          borderRadius: "10px",
        }}
      >
        <Col md={12}>
          <Label>Match 5</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team9"
            placeholder="Team 9"
            value={team9}
            onChange={(e) => setTeam9(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team9, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team10"
            placeholder="Team 10"
            value={team10}
            onChange={(e) => setTeam10(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team10, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
        }}
      >
        <Col md={12}>
          <Label>Match 6</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team11"
            placeholder="Team 11"
            value={team11}
            onChange={(e) => setTeam11(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team11, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team12"
            placeholder="Team 12"
            value={team12}
            onChange={(e) => setTeam12(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team12, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
          background:
            name === "First Group"
              ? "lightblue"
              : name === "Third Group"
              ? "lightcoral"
              : "lightgreen",
          borderRadius: "10px",
        }}
      >
        <Col md={12}>
          <Label>Match 7</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team13"
            placeholder="Team 13"
            value={team13}
            onChange={(e) => setTeam13(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team13, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team14"
            placeholder="Team 14"
            value={team14}
            onChange={(e) => setTeam14(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team14, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>

      <Row
        className="pt-3 pb-3"
        style={{
          textAlign: "center",
        }}
      >
        <Col md={12}>
          <Label>Match 8</Label>
        </Col>
        <Col md={5}>
          <Input
            type="select"
            id="team15"
            placeholder="Team 15"
            value={team15}
            onChange={(e) => setTeam15(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team15, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Input
            type="select"
            id="team16"
            placeholder="Team 16"
            value={team16}
            onChange={(e) => setTeam16(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team16, selectedTeams).map(
              (country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              )
            )}
          </Input>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Group;
