import React, { useState, useEffect } from "react";
import { Button, Row, Spinner } from "reactstrap";
import { createThirdwebClient } from "thirdweb";
import {
  useDisconnect,
  useActiveWallet,
  ConnectButton,
  darkTheme,
} from "thirdweb/react";
import { createWallet, walletConnect } from "thirdweb/wallets";
import Phases from "./Phases";
import { ethers } from "ethers";
import { bracketProxy8 } from "../../assets/abi/Bracket8Proxy";
import { olympicsProxy } from "../../assets/abi/OlympicsProxy";
import { proxyAddress, proxyPhaseOne } from "../../assets/contracts";

const client = createThirdwebClient({
  clientId: process.env.REACT_APP_PUBLIC_THIRDWEB_CLIENT_ID,
});
const wallets = [createWallet("io.metamask"), walletConnect()];

const BotaoConectar = ({ changeChain, chain, activeChain }) => {
  const { disconnect } = useDisconnect();
  const carteira = useActiveWallet();
  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState(null);
  const [game, setGame] = useState(null);

  const [gamePhaseOne, setGamePhaseOne] = useState(null);
  const [gamesPhaseOne, setGamesPhaseOne] = useState(null);

  const formatAddress = () => {
    const address = carteira?.getAccount()?.address;
    return address
      ? `${address.slice(0, 6)}...${address.slice(-4)}`
      : "Not Connected";
  };

  const getActiveGames = async () => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      try {
        const contract = new ethers.Contract(
          proxyAddress,
          bracketProxy8,
          signer
        );
        const activeGames = await contract.getActiveGames();

        const arrayNumber = activeGames.map((game) => game.toNumber());
        setGames(arrayNumber);
        if (arrayNumber.length > 0) setGame(arrayNumber[0]);

        const contractPhaseOne = new ethers.Contract(
          proxyPhaseOne,
          olympicsProxy,
          signer
        );

        const activeGamesPhaseOne = await contractPhaseOne.getActiveGames();
        const arrayNumberPhaseOne = activeGamesPhaseOne.map((game) =>
          game.toNumber()
        );
        setGamesPhaseOne(arrayNumberPhaseOne);
        if (arrayNumberPhaseOne.length > 0) setGamePhaseOne(arrayNumberPhaseOne[0]);
      } catch (error) {
        console.error("Error on getActiveGames: ", error);
      }
    } catch (error) {
      console.error("Error on getActiveGames: ", error);
    } finally {
      setLoading(false);
    }
  };

  const load = async (chain) => {
    console.log("load", chain);
    await changeChain(chain);
    setLoading(false);
  };

  useEffect(() => {
    const getGames = async () => {
      await getActiveGames();
    };

    if (wallet && chain?.network_id !== activeChain?.id) load(chain);
    if (!games) getGames();
  }, [loading, wallet, games]);

  return (
    <React.Fragment>
      {carteira ? (
        <>
          <Button
            color="danger"
            className="btn btn-sm"
            onClick={() => disconnect(carteira)}
          >
            Disconnect
          </Button>
          <p className="mt-2">
            <small className="text-muted">
              Connected Wallet: {formatAddress()}
            </small>
          </p>
          {loading ? (
            <Row className="justify-content-center">
              <Spinner />
            </Row>
          ) : (
            <Phases
              game={game}
              gamePhaseOne={gamePhaseOne}
              setGamePhaseOne={setGamePhaseOne}
              getActiveGames={getActiveGames}
              contrato={proxyAddress}
              bracketProxy8={bracketProxy8}
              contratoPhaseOne={proxyPhaseOne}
              olympicsProxy={olympicsProxy}
            />
          )}
        </>
      ) : (
        <ConnectButton
          client={client}
          wallets={wallets}
          theme={darkTheme({
            colors: {
              modalBg: "#131418",
              dropdownBg: "#131418",
              primaryButtonBg: "#f7666e",
              primaryButtonText: "#ededef",
            },
          })}
          connectModal={{
            size: "compact",
            title: "Escolha sua Carteira",
            showThirdwebBranding: false,
          }}
          onConnect={(wallet) => {
            setLoading(true);
            setWallet(wallet);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default BotaoConectar;
