import axios from "axios";

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

class APIClient {
  contemErrorCode = (str) => {
    return str.includes(" 401");
  };

  /**
   * Fetches data from given url
   */
  get = async (url, params) => {
    const authUser = getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.get(url, { params, headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro = error.response?.data?.mensagem || error.message;
      return {
        mensagem: mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status,
        dados: null,
      };
    }
  };

  /**
   * post given data to url
   */
  post = async (url, data) => {
    const authUser = getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    //aguardar 0.5 segundos antes de enviar a requisição
    // await new Promise((resolve) => setTimeout(resolve, 500));

    try {
      const response = await axios.post(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro = error.response?.data?.mensagem || error.message;
      return {
        mensagem: mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status,
        dados: null,
      };
    }
  };

  /**
   * Updates data
   */
  update = async (url, data) => {
    const authUser = getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.patch(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro = error.response?.data?.mensagem || error.message;
      return {
        mensagem: mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status,
        dados: null,
      };
    }
  };

  put = async (url, data) => {
    const authUser = getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.put(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro = error.response?.data?.mensagem || error.message;
      return {
        mensagem: mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status,
        dados: null,
      };
    }
  };

  /**
   * Delete
   */
  delete = async (url, config) => {
    const authUser = getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.delete(url, { ...config, headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro = error.response?.data?.mensagem || error.message;
      return {
        mensagem: mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status,
        dados: null,
      };
    }
  };
}

export { APIClient, getLoggedinUser };
