import React, { useState } from "react";
import { Row, Col, Nav, NavItem, NavLink, Input, Button } from "reactstrap";
import classnames from "classnames";
import CriarJogo from "./CriarJogo";
import EditarJogo from "./EditarJogo";
import CriarJogoPhaseOne from "./CriarJogoPhaseOne";
import EditarJogoPhaseOne from "./EditarJogoPhaseOne";
import { ethers } from "ethers";
import { toast } from "react-toastify";

import { nftPhaseOne } from "../../assets/contracts";
import { olympicsTicket } from "../../assets/abi/OlympicsTicket";

const Phases = ({
  gamePhaseOne,
  setGamePhaseOne,
  contratoPhaseOne,
  olympicsProxy,
  game,
  getActiveGames,
  contrato,
  bracketProxy8,
}) => {
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState(1);
  const [nftBrackets, setNftBrackets] = useState("");
  const [nftIdPhaseOne, setNftIdPhaseOne] = useState("");
  const [nftImageCheck, setNftImageCheck] = useState(false);
  const [nftPhaseOneImageCheck, setNftPhaseOneImageCheck] = useState(false);
  const [nftImage, setNftImage] = useState("");

  const countries = [
    "Uzbekistan",
    "Spain",
    "Argentina",
    "Morocco",
    "Guinea",
    "New Zealand",
    "Egypt",
    "Dominican Republic",
    "Iraq",
    "Ukraine",
    "Japan",
    "Paraguay",
    "Mali",
    "Israel",
    "France",
    "USA",
  ];

  const getImageId = async (id) => {
    setLoading(true);
    if(id === "0"){
      toast.error("Invalid ID");
      setLoading(false);
      return;
    }
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(nftPhaseOne, olympicsTicket, signer);

      const idToBigNumber = ethers.BigNumber.from(id);
      const metadataJsonBase64 = await contract.tokenURI(idToBigNumber);
      const metadataJson = JSON.parse(atob(metadataJsonBase64.split(",")[1]));
      const imageBase64 = metadataJson.image;
      setNftImage(imageBase64);
      setNftPhaseOneImageCheck(true);
    } catch (error) {
      console.error(error);
      toast.error("Error getting image");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Row className="ms-3 me-3" style={{ textAlign: "left" }}>
        <Nav pills className="nav-justified mb-3">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: phase === 1 })}
              onClick={() => {
                setPhase(1);
              }}
            >
              Phase One
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: phase === 2 })}
              onClick={() => {
                setPhase(2);
              }}
            >
              Phase Two
            </NavLink>
          </NavItem>
        </Nav>
      </Row>
      <h1 className="text-center mt-5">
        {phase === 1 ? "Olympics Phase One" : "Olympics Phase Two"}
      </h1>
      {phase === 1 ? (
        <>
          {nftPhaseOneImageCheck ? (
            <Row className="ms-3 me-3" style={{ textAlign: "center" }}>
              <Col md={12}>
                <h2>Phase One NFT</h2>
                <img src={nftImage} alt="Phase One NFT" />
              </Col>
              <Col md={12} className="mt-3">
                <Button
                  color="primary"
                  onClick={() => {
                    setNftPhaseOneImageCheck(false);
                    setNftImage("");
                  }}
                >
                  Voltar
                </Button>
              </Col>
            </Row>
          ) : (
            <Row className="ms-3 me-3" style={{ textAlign: "center" }}>
              {!gamePhaseOne ? (
                <CriarJogoPhaseOne
                  getActiveGames={getActiveGames}
                  contrato={contratoPhaseOne}
                  olympicsProxy={olympicsProxy}
                  countries={countries}
                />
              ) : (
                <EditarJogoPhaseOne
                  game={gamePhaseOne}
                  setGame={setGamePhaseOne}
                  contrato={contratoPhaseOne}
                  olympicsProxy={olympicsProxy}
                  countries={countries}
                />
              )}
            </Row>
          )}
          <Row className="ms-3 me-3 mt-5" style={{ textAlign: "center" }}>
            <h5>Check NFT Image ID</h5>
            {/* numeric ID */}
            <Col md={6}>
              <Input type="text" onChange={(e) => setNftIdPhaseOne(e.target.value.replace(/\D/g, ""))} />
            </Col>
            <Col md={6}>
              <Button
                color="primary"
                onClick={async () => getImageId(nftIdPhaseOne)}
              >
                Check
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="ms-3 me-3" style={{ textAlign: "center" }}>
          {!game ? (
            <CriarJogo
              getActiveGames={getActiveGames}
              contrato={contrato}
              bracketProxy8={bracketProxy8}
              countries={countries}
            />
          ) : (
            <EditarJogo
              game={game}
              contrato={contrato}
              bracketProxy8={bracketProxy8}
            />
          )}
        </Row>
      )}
    </React.Fragment>
  );
};

export default Phases;
