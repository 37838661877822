import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { ethers } from "ethers";

const CriarJogo = ({ getActiveGames, contrato, bracketProxy8, countries }) => {
  const [loading, setLoading] = useState(false);
  // eight teams
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [team3, setTeam3] = useState("");
  const [team4, setTeam4] = useState("");
  const [team5, setTeam5] = useState("");
  const [team6, setTeam6] = useState("");
  const [team7, setTeam7] = useState("");
  const [team8, setTeam8] = useState("");
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [hash, setHash] = useState("");

  const convertToBytesData = (startTimeStamp, symbols) => {
    const hasThirdPlace = true;
    const dataNewGame = ethers.utils.defaultAbiCoder.encode(
      ["uint256", "string[8]", "bool"],
      [startTimeStamp, symbols, hasThirdPlace]
    );
    return dataNewGame;
  };

  const createGame = async () => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const timestamp = Math.floor(Date.now() / 1000);
      // startTimestamp in 10 minutes
      const startTimestamp = timestamp + 600;

      const symbols = [team1, team2, team3, team4, team5, team6, team7, team8];
      for (let i = 0; i < symbols.length; i++) {
        if (symbols[i] === "") {
          toast.error("All teams must be selected");
          setLoading(false);
          return;
        }
      }

      console.log("startTimestamp", startTimestamp);
      console.log("symbols", symbols);
      const dataNewGame = convertToBytesData(startTimestamp, symbols);

      let receipt;
      try {
        const contract = new ethers.Contract(contrato, bracketProxy8, signer);
        let estimateGas;

        try {
          estimateGas = await contract.estimateGas.performGames(
            dataNewGame,
            "0x",
            timestamp
          );
        } catch (error) {
          console.error(error);
          toast.error("Erro ao estimar GAS");
          setLoading(false);
          return;
        }

        const transactionResponse = await contract.performGames(
          dataNewGame,
          "0x",
          timestamp,
          {
            gasLimit: estimateGas,
          }
        );
        receipt = await transactionResponse.wait();
      } catch (error) {
        console.error(error);
        toast.error("Erro ao enviar transação");
        setLoading(false);
        return;
      }
      setHash(receipt.transactionHash);
      await getActiveGames();
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAvailableCountries = (exception) => {
    return countries.filter((country) => {
      return !selectedTeams.includes(country) || country === exception;
    });
  };

  useEffect(() => {
    const teams = [team1, team2, team3, team4, team5, team6, team7, team8];
    setSelectedTeams(teams.filter((team) => team !== ""));
  }, [team1, team2, team3, team4, team5, team6, team7, team8]);

  return (
    <React.Fragment>
      <h2 className="text-center">Create Game</h2>
      <Row style={{ textAlign: "left" }}>
        <Col md={5}>
          <Label for="team1" className="form-label mt-2">
            Team 1 / Match 1
          </Label>
          <Input
            type="select"
            id="team1"
            placeholder="Team 1"
            value={team1}
            onChange={(e) => setTeam1(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team1).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Label for="team5" className="form-label mt-2">
            Team 5 / Match 3
          </Label>
          <Input
            type="select"
            id="team5"
            placeholder="Team 5"
            value={team5}
            onChange={(e) => setTeam5(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team5).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row style={{ textAlign: "left" }}>
        <Col md={5}>
          <Label for="team2" className="form-label mt-2">
            Team 2 / Match 1
          </Label>
          <Input
            type="select"
            id="team2"
            placeholder="Team 2"
            value={team2}
            onChange={(e) => setTeam2(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team2).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Label for="team5" className="form-label mt-2">
            Team 6 / Match 3
          </Label>
          <Input
            type="select"
            id="team6"
            placeholder="Team 6"
            value={team6}
            onChange={(e) => setTeam6(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team6).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row style={{ textAlign: "left" }} className="mt-5 pt-3">
        <Col md={5}>
          <Label for="team3" className="form-label mt-2">
            Team 3 / Match 2
          </Label>
          <Input
            type="select"
            id="team3"
            placeholder="Team 3"
            value={team3}
            onChange={(e) => setTeam3(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team3).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Label for="team5" className="form-label mt-2">
            Team 7 / Match 4
          </Label>
          <Input
            type="select"
            id="team7"
            placeholder="Team 7"
            value={team7}
            onChange={(e) => setTeam7(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team7).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row style={{ textAlign: "left" }}>
        <Col md={5}>
          <Label for="team4" className="form-label mt-2">
            Team 4 / Match 2
          </Label>
          <Input
            type="select"
            id="team4"
            placeholder="Team 4"
            value={team4}
            onChange={(e) => setTeam4(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team4).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <Label for="team5" className="form-label mt-2">
            Team 8 / Match 4
          </Label>
          <Input
            type="select"
            id="team8"
            placeholder="Team 8"
            value={team8}
            onChange={(e) => setTeam8(e.target.value)}
          >
            <option value="">Select a country</option>
            {getAvailableCountries(team8).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={12}>
          <Button
            color="primary"
            className="btn btn-info"
            onClick={() => createGame()}
            disabled={loading}
          >
            {loading && <Spinner size="sm" />} Create Game
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CriarJogo;
